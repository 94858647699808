// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-de-ch-aktuelles-befluegeltes-leiden-buch-tsx": () => import("H:\\p\\gwi-paprotny-kunstmaler\\src\\pages\\de-ch\\aktuelles\\befluegeltes_leiden_buch.tsx" /* webpackChunkName: "component---src-pages-de-ch-aktuelles-befluegeltes-leiden-buch-tsx" */),
  "component---src-pages-de-ch-aktuelles-index-tsx": () => import("H:\\p\\gwi-paprotny-kunstmaler\\src\\pages\\de-ch\\aktuelles\\index.tsx" /* webpackChunkName: "component---src-pages-de-ch-aktuelles-index-tsx" */),
  "component---src-pages-de-ch-aktuelles-reise-durch-polen-tsx": () => import("H:\\p\\gwi-paprotny-kunstmaler\\src\\pages\\de-ch\\aktuelles\\reise_durch_polen.tsx" /* webpackChunkName: "component---src-pages-de-ch-aktuelles-reise-durch-polen-tsx" */),
  "component---src-pages-de-ch-aktuelles-todesanzeige-tsx": () => import("H:\\p\\gwi-paprotny-kunstmaler\\src\\pages\\de-ch\\aktuelles\\todesanzeige.tsx" /* webpackChunkName: "component---src-pages-de-ch-aktuelles-todesanzeige-tsx" */),
  "component---src-pages-de-ch-beitraege-behauptung-tsx": () => import("H:\\p\\gwi-paprotny-kunstmaler\\src\\pages\\de-ch\\beitraege\\behauptung.tsx" /* webpackChunkName: "component---src-pages-de-ch-beitraege-behauptung-tsx" */),
  "component---src-pages-de-ch-beitraege-einige-worte-ueber-wandmalereien-tsx": () => import("H:\\p\\gwi-paprotny-kunstmaler\\src\\pages\\de-ch\\beitraege\\einige_worte_ueber_wandmalereien.tsx" /* webpackChunkName: "component---src-pages-de-ch-beitraege-einige-worte-ueber-wandmalereien-tsx" */),
  "component---src-pages-de-ch-beitraege-index-tsx": () => import("H:\\p\\gwi-paprotny-kunstmaler\\src\\pages\\de-ch\\beitraege\\index.tsx" /* webpackChunkName: "component---src-pages-de-ch-beitraege-index-tsx" */),
  "component---src-pages-de-ch-galerie-ausstellungen-tsx": () => import("H:\\p\\gwi-paprotny-kunstmaler\\src\\pages\\de-ch\\galerie\\ausstellungen.tsx" /* webpackChunkName: "component---src-pages-de-ch-galerie-ausstellungen-tsx" */),
  "component---src-pages-de-ch-index-tsx": () => import("H:\\p\\gwi-paprotny-kunstmaler\\src\\pages\\de-ch\\index.tsx" /* webpackChunkName: "component---src-pages-de-ch-index-tsx" */),
  "component---src-pages-de-ch-kontakt-tsx": () => import("H:\\p\\gwi-paprotny-kunstmaler\\src\\pages\\de-ch\\kontakt.tsx" /* webpackChunkName: "component---src-pages-de-ch-kontakt-tsx" */),
  "component---src-pages-de-ch-rechtliche-hinweise-tsx": () => import("H:\\p\\gwi-paprotny-kunstmaler\\src\\pages\\de-ch\\rechtliche_hinweise.tsx" /* webpackChunkName: "component---src-pages-de-ch-rechtliche-hinweise-tsx" */),
  "component---src-pages-de-ch-sitemap-tsx": () => import("H:\\p\\gwi-paprotny-kunstmaler\\src\\pages\\de-ch\\sitemap.tsx" /* webpackChunkName: "component---src-pages-de-ch-sitemap-tsx" */),
  "component---src-pages-de-ch-wandmalereien-index-tsx": () => import("H:\\p\\gwi-paprotny-kunstmaler\\src\\pages\\de-ch\\wandmalereien\\index.tsx" /* webpackChunkName: "component---src-pages-de-ch-wandmalereien-index-tsx" */),
  "component---src-pages-de-ch-willkommen-tsx": () => import("H:\\p\\gwi-paprotny-kunstmaler\\src\\pages\\de-ch\\willkommen.tsx" /* webpackChunkName: "component---src-pages-de-ch-willkommen-tsx" */),
  "component---src-pages-de-ch-zur-person-auszeichnung-monte-carlo-tsx": () => import("H:\\p\\gwi-paprotny-kunstmaler\\src\\pages\\de-ch\\zur_person\\auszeichnung_monte-carlo.tsx" /* webpackChunkName: "component---src-pages-de-ch-zur-person-auszeichnung-monte-carlo-tsx" */),
  "component---src-pages-de-ch-zur-person-im-atelier-tsx": () => import("H:\\p\\gwi-paprotny-kunstmaler\\src\\pages\\de-ch\\zur_person\\im_atelier.tsx" /* webpackChunkName: "component---src-pages-de-ch-zur-person-im-atelier-tsx" */),
  "component---src-pages-de-ch-zur-person-index-tsx": () => import("H:\\p\\gwi-paprotny-kunstmaler\\src\\pages\\de-ch\\zur_person\\index.tsx" /* webpackChunkName: "component---src-pages-de-ch-zur-person-index-tsx" */),
  "component---src-pages-de-ch-zur-person-kulturpreis-der-stadt-zabrze-tsx": () => import("H:\\p\\gwi-paprotny-kunstmaler\\src\\pages\\de-ch\\zur_person\\kulturpreis_der_stadt_zabrze.tsx" /* webpackChunkName: "component---src-pages-de-ch-zur-person-kulturpreis-der-stadt-zabrze-tsx" */),
  "component---src-pages-de-ch-zur-person-lebenslauf-tsx": () => import("H:\\p\\gwi-paprotny-kunstmaler\\src\\pages\\de-ch\\zur_person\\lebenslauf.tsx" /* webpackChunkName: "component---src-pages-de-ch-zur-person-lebenslauf-tsx" */),
  "component---src-pages-index-tsx": () => import("H:\\p\\gwi-paprotny-kunstmaler\\src\\pages\\index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pl-pl-aktualnosci-index-tsx": () => import("H:\\p\\gwi-paprotny-kunstmaler\\src\\pages\\pl-pl\\aktualnosci\\index.tsx" /* webpackChunkName: "component---src-pages-pl-pl-aktualnosci-index-tsx" */),
  "component---src-pages-pl-pl-aktualnosci-posmiertny-tsx": () => import("H:\\p\\gwi-paprotny-kunstmaler\\src\\pages\\pl-pl\\aktualnosci\\posmiertny.tsx" /* webpackChunkName: "component---src-pages-pl-pl-aktualnosci-posmiertny-tsx" */),
  "component---src-pages-pl-pl-aktualnosci-uskrzydlone-cierpienie-ksiazka-tsx": () => import("H:\\p\\gwi-paprotny-kunstmaler\\src\\pages\\pl-pl\\aktualnosci\\uskrzydlone_cierpienie_ksiazka.tsx" /* webpackChunkName: "component---src-pages-pl-pl-aktualnosci-uskrzydlone-cierpienie-ksiazka-tsx" */),
  "component---src-pages-pl-pl-aktualnosci-z-podrozy-po-polsce-tsx": () => import("H:\\p\\gwi-paprotny-kunstmaler\\src\\pages\\pl-pl\\aktualnosci\\z_podrozy_po_polsce.tsx" /* webpackChunkName: "component---src-pages-pl-pl-aktualnosci-z-podrozy-po-polsce-tsx" */),
  "component---src-pages-pl-pl-galeria-wystawy-tsx": () => import("H:\\p\\gwi-paprotny-kunstmaler\\src\\pages\\pl-pl\\galeria\\wystawy.tsx" /* webpackChunkName: "component---src-pages-pl-pl-galeria-wystawy-tsx" */),
  "component---src-pages-pl-pl-index-tsx": () => import("H:\\p\\gwi-paprotny-kunstmaler\\src\\pages\\pl-pl\\index.tsx" /* webpackChunkName: "component---src-pages-pl-pl-index-tsx" */),
  "component---src-pages-pl-pl-kontakt-tsx": () => import("H:\\p\\gwi-paprotny-kunstmaler\\src\\pages\\pl-pl\\kontakt.tsx" /* webpackChunkName: "component---src-pages-pl-pl-kontakt-tsx" */),
  "component---src-pages-pl-pl-malarstwo-scienne-index-tsx": () => import("H:\\p\\gwi-paprotny-kunstmaler\\src\\pages\\pl-pl\\malarstwo_scienne\\index.tsx" /* webpackChunkName: "component---src-pages-pl-pl-malarstwo-scienne-index-tsx" */),
  "component---src-pages-pl-pl-mapa-strony-tsx": () => import("H:\\p\\gwi-paprotny-kunstmaler\\src\\pages\\pl-pl\\mapa_strony.tsx" /* webpackChunkName: "component---src-pages-pl-pl-mapa-strony-tsx" */),
  "component---src-pages-pl-pl-odnosnie-osoby-index-tsx": () => import("H:\\p\\gwi-paprotny-kunstmaler\\src\\pages\\pl-pl\\odnosnie_osoby\\index.tsx" /* webpackChunkName: "component---src-pages-pl-pl-odnosnie-osoby-index-tsx" */),
  "component---src-pages-pl-pl-odnosnie-osoby-nagroda-kultury-miasta-zabrze-tsx": () => import("H:\\p\\gwi-paprotny-kunstmaler\\src\\pages\\pl-pl\\odnosnie_osoby\\nagroda_kultury_miasta_zabrze.tsx" /* webpackChunkName: "component---src-pages-pl-pl-odnosnie-osoby-nagroda-kultury-miasta-zabrze-tsx" */),
  "component---src-pages-pl-pl-odnosnie-osoby-w-pracowni-tsx": () => import("H:\\p\\gwi-paprotny-kunstmaler\\src\\pages\\pl-pl\\odnosnie_osoby\\w_pracowni.tsx" /* webpackChunkName: "component---src-pages-pl-pl-odnosnie-osoby-w-pracowni-tsx" */),
  "component---src-pages-pl-pl-odnosnie-osoby-wyroznienie-w-monte-carlo-tsx": () => import("H:\\p\\gwi-paprotny-kunstmaler\\src\\pages\\pl-pl\\odnosnie_osoby\\wyroznienie_w_monte-carlo.tsx" /* webpackChunkName: "component---src-pages-pl-pl-odnosnie-osoby-wyroznienie-w-monte-carlo-tsx" */),
  "component---src-pages-pl-pl-odnosnie-osoby-zyciorys-tsx": () => import("H:\\p\\gwi-paprotny-kunstmaler\\src\\pages\\pl-pl\\odnosnie_osoby\\zyciorys.tsx" /* webpackChunkName: "component---src-pages-pl-pl-odnosnie-osoby-zyciorys-tsx" */),
  "component---src-pages-pl-pl-wkladki-index-tsx": () => import("H:\\p\\gwi-paprotny-kunstmaler\\src\\pages\\pl-pl\\wkladki\\index.tsx" /* webpackChunkName: "component---src-pages-pl-pl-wkladki-index-tsx" */),
  "component---src-pages-pl-pl-wkladki-kilka-slow-o-malarstwie-sciennym-tsx": () => import("H:\\p\\gwi-paprotny-kunstmaler\\src\\pages\\pl-pl\\wkladki\\kilka_slow_o_malarstwie_sciennym.tsx" /* webpackChunkName: "component---src-pages-pl-pl-wkladki-kilka-slow-o-malarstwie-sciennym-tsx" */),
  "component---src-pages-pl-pl-wkladki-twierdzenie-tsx": () => import("H:\\p\\gwi-paprotny-kunstmaler\\src\\pages\\pl-pl\\wkladki\\twierdzenie.tsx" /* webpackChunkName: "component---src-pages-pl-pl-wkladki-twierdzenie-tsx" */),
  "component---src-pages-pl-pl-wskazowki-prawne-tsx": () => import("H:\\p\\gwi-paprotny-kunstmaler\\src\\pages\\pl-pl\\wskazowki_prawne.tsx" /* webpackChunkName: "component---src-pages-pl-pl-wskazowki-prawne-tsx" */),
  "component---src-pages-pl-pl-zaprasza-tsx": () => import("H:\\p\\gwi-paprotny-kunstmaler\\src\\pages\\pl-pl\\zaprasza.tsx" /* webpackChunkName: "component---src-pages-pl-pl-zaprasza-tsx" */),
  "component---src-pages-styleguide-index-tsx": () => import("H:\\p\\gwi-paprotny-kunstmaler\\src\\pages\\styleguide\\index.tsx" /* webpackChunkName: "component---src-pages-styleguide-index-tsx" */)
}

